@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--sha-orange: #f4592f;
	--sha-pink: #f8a5c7;
	--sha-bg: #fef4ea;
	--sha-font: #202020;
}
